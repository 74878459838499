import React from 'react';
import { Link } from 'react-router-dom';

class Nav extends React.Component {

	render() {
		return (
			<header>
			<nav className="nav">
					<Link title="Home page" to="/">
						<div className="logo">
							
								<img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/homeLogo_animate.svg" alt="rudes logo" />
							
						</div>
					</Link>
					<ul>
						{/*<li><Link to=""  className="burger" >&#9776;</Link></li>*/}
						<Link title="Work Page" to="/work"  className="" ><li>WORK</li></Link>
						<Link title="About me page" to="/aboutme"  className="" ><li>ABOUT ME</li></Link>
					</ul>
			</nav>
			</header>
			)
	}

}


export default Nav;