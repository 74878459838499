import React from 'react';


const NotFound = () => (

	<div>
		<h2>Not Found </h2>
	</div>

)


export default NotFound;