import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Link } from 'react-router-dom';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);


// simulate loading data from a server
function fetchFakeData() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve([
                {},
                {},
                {},
            ]);
        }, 2000);
    });
}


const Work = props => {


    const [loadingState, setLoadingState] = useState();
    const [data, setData] = useState([]);

    let jobs = props.jobs;

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {

        if (loadingState !== "start") return;

        const loadData = async () => {
            const data = await fetchFakeData();
            setData(data);
            setLoadingState("complete");
        }
        loadData();

    }, [loadingState]);


    useLayoutEffect(() => {

        if (loadingState !== "complete") return;

        revealRefs.current.forEach((el, index) => {

            gsap.fromTo(el, {
                autoAlpha: 0,
                y: 20
            }, {
                duration: 0.75,
                autoAlpha: 1,
                y: 0,
                ease: 'back.inOut(1.7)',
                scrollTrigger: {
                    id: `${index}-listWork`,
                    trigger: el,
                    start: 'top center+=100',
                    toggleActions: 'play none none reverse',
                }
            });

        });

    }, [loadingState]);


    const startLoading = () => {
        if (!loadingState) {
            setLoadingState("start");
        }
    };

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    startLoading();





    return (


        <main id="maincontent" className="wgrid">
					<span tabIndex="0" aria-label="This is my work history." className="worktitle">Work</span>
					<ul  className="worklistGrid">
						
       
				      { 
				        loadingState === "start" 
				          ? <div className="loadLogo">
				          			
				          		<img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/logo_animate.svg" alt="rudes" />
				          	</div> 
				          : null 
				      }


						{jobs.map((job, index)=>(

							<li key={index}  id={`${index}-listWork`} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} ref={addToRefs}>
								<Link title={job.client} to={"/work/"+job.client}  >
									<div className="worklistName maskSpan">
									
										<img src={job.preview} alt="" />
											<div>
													<span>
													{job.client}
													</span>
											</div>	
									</div>
								</Link>
							</li>

						))}

					</ul>
			</main>


    )


}

export default Work;