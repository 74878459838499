import React, { useRef, useLayoutEffect } from 'react';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);


const Sample = ({ setPopUp, setVideo, clientName }) => {



    //let { id } = useParams();

    // let index = props.client.findIndex(p => p.client === id);	

    // let clientName = props.client[index];
    const modalBgRef = useRef(null);
    const videoRef = useRef(null);

    useLayoutEffect(() => {

        gsap.fromTo(modalBgRef.current, {
            autoAlpha: 0,
            scale: 0.8,

            delay: 0
        }, {
            autoAlpha: 1,
            scale: 1,
            ease: 'power4.out',
            duration: 1,
        });

        gsap.fromTo(videoRef.current, {
            autoAlpha: 0,
            ease: 'none',
            delay: 1
        }, {
            autoAlpha: 1,
        });


    });

    return (

        <div className="vidModal" onClick={()=> setPopUp(false)} >

					
			<div className="modalBg" ref={modalBgRef}></div>

			<div className="xBtn" ><h1>X</h1></div>
			<div className="modalVid" ref={videoRef}>
				<video playsInline autoPlay muted loop id="video3">
					<source src={"https://rudes-media.s3.us-west-1.amazonaws.com/" + `${clientName}` + "/" + `${setVideo}` + ".mp4"} type="video/mp4" />
				</video>
			</div>

		</div>




    )
}


export default Sample;