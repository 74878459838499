import React from 'react';

class Footer extends React.Component {

	render() {
		return (
			<footer>
					<div className="contactcont">
						<span>&nbsp; CALL ME MAYBE &nbsp; </span>
						<span><a title="Call my mobile telephone" href="tel:1-323-356-3752">&nbsp; 323-356-3752 &nbsp;</a></span>
						<span><a title="Email me" href="mailto:rrudy90023@gmail.com?Subject=Call%20me%20maybe" target="top">&nbsp; rrudy90023@gmail.com &nbsp;</a></span>
					</div>
					<div className="footcontainer">
						<div className="footicon"><a title="LinkedIn account" rel="nofollow noopener noreferrer" href="https://www.linkedin.com/in/rudes-de/" target="_blank" ><img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/linkedin.svg" alt="" /></a>
						</div>
						<div className="footicon"><a title="Github account" rel="nofollow noopener noreferrer" href="http://github.com/rrudy90023" target="_blank" ><img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/git.svg" alt="" /></a>
						</div>
						<div className="footicon"><a title="Instagram account" rel="nofollow noopener noreferrer" href="http://instagram.com/rrudy90023" target="_blank" ><img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/insta.svg" alt="" /></a></div>
						<div className="footicon"><a title="Twitter account" rel="nofollow noopener noreferrer" href="http://twitter.com/rudy90023" target="_blank" ><img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/twit.svg" alt="" /></a></div>
						<div className="footicon"><a title="My blog" rel="nofollow noopener noreferrer" href="http://rudes.de/blog/" target="_blank" ><img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/word.svg" alt="" /></a></div>
					</div>
			</footer>
			)
	}

}


export default Footer;