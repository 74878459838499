import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './NotFound';
import Profile from './Profile';
import Home from './Home';
import Nav from './Nav';
import Footer from './Footer';
import Work from './Work';
import jobs from '../jobs'
import Client from './Client';
import '../styles/css/main.css';

class App extends React.Component {

    state = {
        jobs
    };


    render() {
        return (
            <BrowserRouter>
			<Nav />
				<Switch>
					<Route exact path="/" >
						<Home/>
					</Route>

					<Route exact path="/aboutme" >
						<Profile/>
					</Route>

					<Route path="/work/:id" >
						<Client client={this.state.jobs}/>
					</Route>

					<Route exact path="/work" >
						<Work jobs={this.state.jobs}/>
					</Route>

					<Route >
						<NotFound/>
					</Route>

				</Switch>
			<Footer />
		</BrowserRouter>
        );
    }
}

export default App;