const jobs = [

	{
		client: "David&Goliath",
		desc: "As a Tech Lead, I developed pixel-perfect UIs with API integration, oversaw agency and client web apps, and coached a junior developer.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/dng.jpg",
		images: ["dng_01.jpg"],
		videos: "",
		url: "https://kiabrochure.com/#/forte"  
	},

	{
		client: "MullenLowe",
		desc: "My versatile role at MullenLowe included developing or prototyping platforms for social, outdoor interactive, AR(Spark AR), banner ads, and machine-learning.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/mullen.jpg",
		images: ["mullen_04.jpg", "mullen_05.jpg", "mullen_02.jpg", "mullen_03.jpg", "mullen_01.jpg"],
		videos: ["combo", "providence" ,"wholefoods", "acura", "eva"],
		url: ""  
	},


 	{
		client: "Ugly-Sweater",
		desc: "This is a machine-learning physical-interface I developed while working in MullenLowe. The software and hardware to make this interface consisted of an Arduino, a Photon.io, a webcam mounted on a board, and a laptop connected to a monitor to display the meter. The app was developed by myself using Google's Teacheable machine API and a Chrome-app based API called Involt. I also used the Paticle.io API to send requests on my remote Photon. This was a experimental prototype to demonstrate to the agency emerging technologies that may disrupt advertising norms. Please click on the image to see a in-depth blog post about the case study.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/uglysweater.jpg",
		images: ["uglysweater_01.jpg"],
		videos: "",
		url: "http://rudes.de/blog/the-journey-of-the-ugly-sweater/"
	},

 	{
		client: "Media-Arts-Lab",
		desc: "While working in MAL, I was involved developing digital ads for clients, maintained a content management system, managed 3rd party projects. The data visualization in-house app was co-developed by myself and a Senior Developer. It was developed using React.js when it was in beta, version 0.14.0.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/ipad.jpg",
		images: ["infog_01.jpg", "infog_02.jpg", "infog_03.jpg"],
		videos: "",
		url: "https://infogmal.herokuapp.com/geos"
	},

	{
		client: "KoolAid",
		desc: "Working in Lunchbox I developed microsites for Walmart. This microsite is a KoolAid shareable card creator. The user can customize a card and share it through a unique URL. It is responsive on all devices.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/kool.jpg",
		images: ["kool_01.jpg", "kool_02.jpg", "kool_03.jpg"],
		videos: "",
		url: "https://rudes-media.s3.us-west-1.amazonaws.com/koolaid/kct.html"
	},

 	{
		client: "Power-Rangers",
		desc: "I developed various microsites for clients while in Lunchbox. This site consisted of a Power Ranger-themed letter generator for children. The user can customize a card and share it.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/pw.jpg",
		images: ["pr_01.jpg", "pr_02.jpg"],
		videos: "",
		url: "https://rudes-media.s3.us-west-1.amazonaws.com/power-rangers/powerrangers.html"
	},

 	{
		client: "180LA",
		desc: "In 180LA, I helped create various content for web, social, and new-business presentations. My role was to be developer and technologist for the creative team.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/180la.jpg",
		images: ["180la_01.jpg", "180la_03.jpg", "180la_04.jpg", "180la_02.gif"],
		videos: ["pepsi", "postmates", "uopx", "halloween"],
		url: ""
	},

 	{
		client: "Deutsch",
		desc: "Online advertising, banner-rich media, social, and page overlays were some of the many campaigns I was involved in while employed in Deutsch.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/vw.jpg",
		images: ["vw_01.jpg", "vw_02.jpg", "htc_01.jpg", "htc_03.jpg"],
		videos: "",
		url: ""
	},

 	{
		client: "TBWA-Chiat-Day",
		desc: "Various web advertising such as rich media, banner ads, and micro-sites was part of many campaigns I was involved with in TBWA/Chiat/Day LA.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/nissan.jpg",
		images: ["nissan_01.jpg", "nissan_02.jpg"],
		videos: "",
		url: ""
	},

 	{
		client: "TMZ",
		desc: "During the start-up, I managed and oversaw the interactive department. Also, I developed and designed web content such as interactive flash games, microsites, online video, and brand identity.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/tmz.jpg",
		images: ["tmz_01.jpg", "tmz_02.jpg"],
		videos: "",
		url: ""
	},

 	{
		client: "Zap2it",
		desc: "Working at Zap2it, I handled the design and development of interactive games, ads, and web pages.",
		preview: "https://rudes-media.s3.us-west-1.amazonaws.com/images/zap.jpg",
		images: ["zap_01.jpg", "zap_02.jpg"],
		videos: "",
		url: ""
	}
]


export default jobs;