import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap/all";
// simulate loading data from a server
function fetchFakeData() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve([
                {},
                {},
                {},
            ]);
        }, 2000);
    });
}

const Profile = props => {


    const [loadingState, setLoadingState] = useState();
    const [data, setData] = useState([]);

    const headerRef = useRef(null);

    useEffect(() => {

        if (loadingState !== "start") return;

        const loadData = async () => {
            const data = await fetchFakeData();
            setData(data);
            setLoadingState("complete");
        }
        loadData();

    }, [loadingState]);


    useLayoutEffect(() => {

        if (loadingState !== "complete") return;


        gsap.fromTo(headerRef.current, {
            autoAlpha: 0,
            ease: 'none'
        }, {
            autoAlpha: 1
        });

    }, [loadingState]);


    const startLoading = () => {
        if (!loadingState) {
            setLoadingState("start");
        }
    };



    startLoading();

    return (


        <div>

			      { 
			        loadingState === "start" 
			          ? <div className="loadLogo">
			          			
			          		<img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/logo_animate.svg" alt="rudes" />
			          	</div> 
			          : null 
			      }

				<main id="maincontent" className="profile" ref={headerRef}>

					<span tabIndex="0" aria-label="About me" className="greet">About Me</span>
						<div className="container">
							
							<div className="profileCont">
								<img tabIndex="0" src="https://rudes-media.s3.us-west-1.amazonaws.com/images/me.png" alt="rudes" />
							</div>
							
							<div tabIndex="0" className="holder" aria-label="18 years of creating interactive experiences for award-winning agencies with a lateral-thinking, self-starting attitude. 
									I have proven my longevity by evolving with the industry as a front-end developer, technologist, and motion designer. My belief in putting theory into practice demonstrates my interdisciplinary knowledge. 
									I understand the importance of an intuitive interface and eye-catching motion design. I have perfected cross-functional collaboration by my genuine love to connect with individuals.
									
									I’m a native Angeleno, son of Mexican immigrant parents. I’m a charming introvert, a Cinema Arts major, and a UX designer in training. 
									I’m also a person who understands first hand the inequalities in our inner cities.
									As an environmental activist, I firmly believe in the impact of interpersonal engagement to better solve challenges for future generations." >

								<p className="para">
									18 years of creating interactive experiences for award-winning agencies with a lateral-thinking, self-starting attitude. 
									I have proven my longevity by evolving with the industry as a front-end developer, technologist, and motion designer. My belief in putting theory into practice demonstrates my interdisciplinary knowledge. 
									I understand the importance of an intuitive interface and eye-catching motion design. I have perfected cross-functional collaboration by my genuine love to connect with individuals.
								</p>	
								<p className="para">
									I’m a native Angeleno, son of Mexican immigrant parents. I’m a charming introvert, a Cinema Arts major, and a UX designer in training. 
									I’m also a person who understands first hand the inequalities in our inner cities.
									As an environmental activist, I firmly believe in the impact of interpersonal engagement to better solve challenges for future generations.
									
								</p>
								<p className="dlResume">
									<a title="Download my resume" rel="nofollow noopener noreferrer" href="https://rudes-media.s3.us-west-1.amazonaws.com/images/Rudy-Perez-Resume.pdf" target="_blank" >Download my resume (PDF link)</a>
								</p>
							</div>

						</div>
				</main>

				</div>

    )


}

export default Profile;