import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { gsap } from "gsap/all";
// simulate loading data from a server
function fetchFakeData() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve([
        {  },
        {  },
        {  },
      ]);
    }, 2000);
  });  
}


const Home = ()=> {


		const [loadingState, setLoadingState] = useState();
		const [data, setData] = useState([]);

		  const headerRef = useRef(null);

		  useEffect(() => {
		    
		    if (loadingState !== "start") return;
		    
		    const loadData = async () => {
		      const data = await fetchFakeData();
		      setData(data);
		      setLoadingState("complete");
		    }
		    loadData();    
		    
		  }, [loadingState]);


		  useLayoutEffect(() => {
		    
		  	if (loadingState !== "complete") return;

		    
		    gsap.fromTo(headerRef.current, {
		      autoAlpha: 0, 
		      ease: 'none'
		    },{
		    	autoAlpha: 1
		    });

		  }, [loadingState]);


		  const startLoading = () => {
		    if (!loadingState) {
		      setLoadingState("start");
		    }
		  };



		  startLoading();




		return(

			<section>

		      		{ 
			        loadingState === "start" 
			          ? <div className="loadLogo">
			          			
			          		<img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/logo_animate.svg" alt="rudes"/>
			          	</div> 
			          : null 
			      	}
			      	<a className="skip-link" href="#maincontent">Skip to main</a>
					<main id="maincontent" className="primary" ref={headerRef}>

						<video className="video" playsInline autoPlay muted loop width="100%" height="100%">
						  <source src="https://rudes-media.s3.us-west-1.amazonaws.com/videos/loop3.mp4" type="video/mp4" />
						</video>
						<div>
							<span tabIndex="0" aria-label="My name is Rudy Perez, a native Angeleno currently living in Boyle Heights. I am a programmer and an enthusiast of many things. I've been making digital content for big agencies and companies for more than 18 years." className="spanHome">
								My name is Rudy Perez, a native Angeleno currently living in Boyle Heights. I am a developer and an enthusiast of many things. I've been making digital content for big agencies and companies for more than 18 years.
							</span>
						</div>
					</main>


			</section>
		)

}



export default Home;