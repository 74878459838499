import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useParams } from 'react-router-dom';
import Sample from './Sample'
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);


// simulate loading data from a server
function fetchFakeData() {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve([
                {},
                {},
                {},
            ]);
        }, 2000);
    });
}



const Client = props => {

    const [loadingState, setLoadingState] = useState();

    const [data, setData] = useState([]);

    const [popUp, setPopUp] = useState(false);

    const [video, setVideo] = useState("banna");

    let { id } = useParams();

    let index = props.client.findIndex(p => p.client === id);

    let clientIndex = props.client[index];

    let videoPath = clientIndex.videos;

    useEffect(() => {

        if (loadingState !== "start") return;

        const loadData = async () => {
            const data = await fetchFakeData();
            setData(data);
            setLoadingState("complete");
        }
        loadData();

    }, [loadingState]);


    const revealRefs = useRef([]);
    revealRefs.current = [];


    useLayoutEffect(() => {

        if (loadingState !== "complete") return;

        revealRefs.current.forEach((el, index) => {

            gsap.fromTo(el, {
                autoAlpha: 0
            }, {
                duration: 0.75,
                autoAlpha: 1,
                ease: 'back.inOut(1.7)',
                scrollTrigger: {
                    id: `${index}-listWork`,
                    trigger: el,
                    start: 'top center+=100',
                    toggleActions: 'play none none reverse'
                }
            });

        });

    }, [loadingState]);


    const startLoading = () => {
        if (!loadingState) {
            setLoadingState("start");
        }
    };


    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    startLoading();
    // const setVideoFunc = (str) => {
    //   setVideo(str);
    //   console.log(str);
    // }


    let clickCheck = videoPath === "" ? false : true;



    return (

        <main id="maincontent" className="wgrid">
				<div className="clientHeader">

					<h1 tabIndex="0" aria-label={id} className="clientName">{id}</h1><br/>

					<span tabIndex="0" aria-label={clientIndex.desc} className="clientDesc">{clientIndex.desc}</span><br/>

				</div>

				<ul className="clientlistGrid">


			      { 
			        loadingState === "start" 
			          ? <div className="loadLogo">
			          			
			          		<img src="https://rudes-media.s3.us-west-1.amazonaws.com/images/logo_animate.svg" alt="rudes"/>
			          	</div> 
			          : null 
			      }

					
					{clientIndex.images.map((image, index)=> {
						return [

							clientIndex.url &&

								<a key={index} rel="nofollow noopener noreferrer" href={clientIndex.url} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} target="_blank" >
								
								<li key={index} title={clientIndex.client} ref={addToRefs} >
									

										<div className="worklistName">

											<img src={"https://rudes-media.s3.us-west-1.amazonaws.com/" +`${clientIndex.client.toLowerCase()}` + "/" + clientIndex.images[index]} className="coholder2"  alt={clientIndex.client}/>
												
												<div className="maskSpan">
													
													<span className="capClient">
													{ "View Website >>"}
													</span>
													
												</div>	
												
										</div>
								</li> 
								</a>
	
							,
					
							videoPath &&

								<li key={index} ref={addToRefs} onClick={()=> { setPopUp(clickCheck); setVideo(videoPath[index]) }}>
										<div className="worklistName">
											<img src={"https://rudes-media.s3.us-west-1.amazonaws.com/" +`${clientIndex.client.toLowerCase()}` + "/" + clientIndex.images[index]} className="coholder2"  alt={clientIndex.client}/>
												
												<div className="maskSpan">
													
													<span className="capClient">
													{ "View Video >>"}
													</span>
												
												</div>
										</div>
								</li> 
							,

							(!clientIndex.url && !videoPath) &&
							
								<li key={index} ref={addToRefs}>
											<img src={"https://rudes-media.s3.us-west-1.amazonaws.com/" +`${clientIndex.client.toLowerCase()}` + "/" + clientIndex.images[index]} className="coholder2" alt={clientIndex.client}/>
								</li>

						]	
						
					})
					}
				</ul>
				{popUp && <Sample setPopUp={setPopUp} setVideo={video} clientName={clientIndex.client.toLowerCase()} />}
		</main>

    )
}


export default Client;